import { CompanyData } from "../../Companies";
import Card from "@kamae-apps/shared/Component/Card/Card";
import Input from "@kamae-apps/shared/Component/Input/Input";
import { ChangeEvent, FC, useCallback, useState } from "react";
import PrimaryButton from "@kamae-apps/shared/Component/Button/PrimaryButton";
import { apiRequest } from "@kamae-apps/shared/utils";
import { useToast } from "@kamae-apps/shared/Component/Toast/Context";
import { successToast } from "@kamae-apps/shared/Component/Toast/ToastBuilder";
import { useApiRequest } from "@kamae-apps/shared/Hooks/useApiRequest";
import TTeam from "@kamae-apps/shared/Types/Team/TTeam";
import Select from "@kamae-apps/shared/Component/Input/Select";
import TUser from "@kamae-apps/shared/Types/TUser";
import useReload from "@kamae-apps/shared/Hooks/useReload";

interface CompanyActionsProps {
  company: CompanyData
}

export const CompanyActions: FC<CompanyActionsProps> = ({company}) => {
  const [week, setWeek] = useState(4);
  const [score, setScore] = useState(10);
  const toast = useToast();
  const teams = useApiRequest<TTeam[]>(`/teams/company/${company.id}`, [company.id]);
  const [team, setTeam] = useState<number>();
  const { reload, doReload} = useReload();
  const user = useApiRequest<TUser>("/user", [reload]);
  const changeWeek = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.currentTarget.value === "" ?
      setWeek(0) :
      setWeek(parseInt(e.currentTarget.value))
  }, []);
  const sendMailWeek = useCallback(() => {
    apiRequest(`/companies/${company.id}/week-reminder?week=${week}`, {method: "POST"}).then(() => {
      toast.addToast(successToast(""))
    });
  }, [company.id, toast, week]);
  const changeScore = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.currentTarget.value === "" ?
      setScore(0) :
      setScore(parseInt(e.currentTarget.value));
  }, []);
  const sendMailScore = useCallback(() => {
    apiRequest(`/companies/${company.id}/score-reminder?maxScore=${score}`, {method: "POST"}).then(() => {
      toast.addToast(successToast(""));
    });
  }, [company.id, score, toast]);
  const moveToCompany = useCallback(async () => {
    await apiRequest(`/user/${user?.id}`, {method: "PUT", body: {company: company.id, team: team}})
    doReload();
    toast.addToast(successToast("Déplacé dans l'entreprise " + company.name))
  }, [company.id, company.name, doReload, team, toast, user?.id])
  return (
    <div className={"flex-col flex gap-2 p-2"}>
      <div className={"flex gap-2"}>
        <Card className={"drop-shadow-md"}>
          <h2>Relance par date de connexion</h2>
          <div className={"flex items-center"}>
            <Input
              className={"my-4 !ml-0 !w-auto"}
              placeholder={"Semaine"}
              value={week}
              onChange={changeWeek}
            />
            <PrimaryButton className={"!m-0"} onClick={sendMailWeek} disabled={week <= 0}>
              Envoyer les notifications
            </PrimaryButton>
          </div>
          <p className={"text-gray-400 text-sm"}>Relance les utilisateurs ne s'étant pas connecter depuis le nombre de semaine indiqué</p>
        </Card>
        <Card className={"drop-shadow-md"}>
          <h2>Relance par score</h2>
          <div className={"flex items-center"}>
            <Input
              className={"my-4 !ml-0 !w-auto"}
              placeholder={"Score"}
              value={score}
              onChange={changeScore}
            />
            <PrimaryButton className={"!m-0"} onClick={sendMailScore} disabled={week <= 0}>
              Envoyer les notifications
            </PrimaryButton>
          </div>
          <p className={"text-gray-400 text-sm"}>Relance les utilisateurs n'ayant pas atteint le score indiqué</p>
        </Card>
      </div>
      <div className={"flex gap-2"}>
        <Card className={"drop-shadow-md"}>
          <h2>Se déplacer dans l'entreprise</h2>
          <div className={"flex items-end"}>
            <Select className={"w-60 mt-4"} placeholder={"Équipe"} defaultValue={team} options={teams?.map(t => ({name: t.name, value: t.id})) ?? []} onChange={e => setTeam(parseInt(e.currentTarget.value))}/>
            <PrimaryButton disabled={user?.company.id === company.id || team === undefined} className={"!m-0"} onClick={moveToCompany}>GO</PrimaryButton>
          </div>
        </Card>
      </div>
    </div>
  );
}
